import styled from 'styled-components';

export const PageTitle = styled.h1`
  font-size: 66px;
  font-weight: 900;
  color: var(--color-dark);
  margin-left: var(--indent-spacing);
  @media (max-width: 970px) {
    font-size: 56px;
  }
  @media (max-width: 800px) {
    font-size: 46px;
  }
  @media (max-width: 700px) {
    font-size: 36px;
  }
  @media (max-width: 570px) {
    font-size: 30px;
  }

  @media (max-width: 470px) {
    font-size: 24px;
  }
  @media (max-width: 370px) {
    font-size: 20px;
  }
`;

import styled from 'styled-components';

export const FreeQuoteBanner = styled.a`
  position: fixed;
  top: 12px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 3;
  border-radius: 40px;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0.5px;
  color: white;
  text-shadow: 0 1px 5px rgba(0, 0, 0, 0.2);
  box-shadow: 0 4px 24px -4px rgba(0, 0, 0, 0.29),
    0px 5px 16px -12px rgba(0, 0, 0, 0.95);
  background: var(--color-orange);
  border: 2px solid rgba(0, 0, 0, 0.13);
  padding: 4px 24px;
  text-decoration: none;
  min-width: 380px;
  text-align: center;
  @media (max-width: 570px) {
    padding: 4px 18px;
    font-size: 12px;
    min-width: 300px;
  }
`;

import styled from 'styled-components';

export const CardTitle = styled.h2`
  font-weight: 900;
  color: var(--color-soft-dark);
  font-size: 24px;
  margin-bottom: 12px;
  padding-bottom: 6px;
  position: relative;
  flex: 1;
  @media (max-width: 600px) {
    font-size: 20px;
  }
  @media (max-width: 400px) {
    font-size: 18px;
  }
  margin-top: 40px;
  &:first-of-type {
    margin-top: 0;
  }
  &::after {
    content: '';
    position: absolute;
    left: 0;
    bottom: -3px;
    width: 60px;
    height: 3px;
    background: var(--color-orange);
  }
`;

import styled from 'styled-components';

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 32px;
  padding-top: 70px;
`;

const CopyrightText = styled.p`
  font-size: 14px;
  color: var(--color-dark);
  opacity: 0.4;
  font-weight: 500;
  text-align: center;
  margin-top: 98px;
  @media (max-width: 400px) {
    font-size: 12px;
  }
`;

type Props = {};

export const Footer: React.FC<Props> = () => {
  return (
    <Container>
      <CopyrightText>
        © {new Date().getFullYear()} Designed & Coded by Tyler Holden
      </CopyrightText>
    </Container>
  );
};

export function truncateString(
  str: string | undefined,
  num: number,
  allowWordToFinish?: boolean
) {
  if (!str) return '';
  if (str.length <= num) {
    return str;
  }
  const hardTrimmedStr = str.slice(0, num);
  if (allowWordToFinish) {
    if (hardTrimmedStr.endsWith(' ')) {
      return hardTrimmedStr.trim() + '...';
    } else {
      const wordBeingSliced = str.slice(num).split(' ')[0];
      return (hardTrimmedStr + wordBeingSliced).trim() + '...';
    }
  } else {
    return hardTrimmedStr + '...';
  }
}

import styled from 'styled-components';

const CallForQuoteLink = styled.a`
  margin-top: 40px;
  padding: 20px;
  background: var(--color-orange);
  color: white;
  display: inline-block;
  font-weight: 700;
  text-shadow: 0 1px 8px rgba(0, 0, 0, 0.1);
  outline: none;
  text-decoration: none;
  border-radius: 0;
  @media (max-width: 400px) {
    font-size: 14px;
  }
`;

export const CallForQuote = () => {
  return (
    <CallForQuoteLink href='tel:5128000798'>
      Give Us A Call 512-800-0798
    </CallForQuoteLink>
  );
};

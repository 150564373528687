import React from 'react';
import { createPortal } from 'react-dom';
import styled from 'styled-components';
import { useDelayMount } from './useDelayMount';

type Props = {
  onClose?: (e: any) => void;
  isOpen: boolean;
  onBackdropClick?: (e: any) => void;
  title?: string;
  showCancelButton?: boolean;
  onPrimaryActionClick?: () => void;
  primaryActionButtonText?: string;
  className?: string;
};

const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  backdrop-filter: blur(22px);
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.2s ease;
  z-index: 9998;
  &.open {
    opacity: 1;
    pointer-events: auto;
  }
`;

const Modal = styled.div`
  -webkit-animation: tilt-in-fwd-bl 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94)
    0.05s both;
  animation: tilt-in-fwd-bl 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.05s both;
  width: 100%;
  max-width: var(--max-width, 700px);
  background: white;
  color: #222;
  overflow: scroll;
  border-radius: 0px;
  box-shadow: 0 4px 38px rgb(0 0 0 / 05%), 0 26px 24px -26px rgb(0 0 0 / 07%);
  margin: 16px;
`;

const ModalActionButtons = styled.div`
  display: flex;
  align-items: stretch;
  height: 42px;
`;

const ActionButton = styled.button`
  flex: 1;
  margin: 0;
  outline: none;
  border-radius: 0;
  border: none;
  background: transparent;
  cursor: pointer;
  font-weight: 700;
  letter-spacing: 2px;
  font-size: 14px;
  text-transform: uppercase;
  && {
    border-radius: 0;
    border-top: 1px solid #dadada;
    border-right: 1px solid #dadada;
    font-size: 16px;
    &:last-of-type {
      border-right: none;
    }
  }
  &:hover {
    background: rgba(0, 0, 0, 0.06);
  }
`;

const CancelActionButton = styled(ActionButton)`
  .btn-text {
    color: var(--color-muted-text);
  }
`;

const PrimaryActionButton = styled(ActionButton)`
  .btn-text {
    color: var(--primary-action-color, --color-white);
  }
`;

const ModalContent = styled.div`
  max-height: 70vh;
  overflow: scroll;
  > button {
    margin-left: 0;
    min-width: 140px;
  }
`;

const ModalTitleBar = styled.div`
  width: 100%;
  padding: 10px 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid rgba(0, 0, 0, 0.08);
  p {
    padding-left: 8px;
    padding-right: 8px;
    padding-top: 6px;
    padding-bottom: 6px;
  }
  .version {
    font-size: 10px;
    opacity: 0.7;
  }
`;

const CloseButton = styled.button`
  width: 15px;
  height: 15px;
  background: #b12f2f;
  opacity: 1;
  border-radius: 50%;
  padding: 0;
  border: none;
  margin-left: 6px;
  margin-right: 25px;
  &:hover {
    opacity: 0.7;
  }
`;

const ModalBase: React.FC<Props> = ({
  onClose,
  isOpen,
  children,
  onBackdropClick,
  title = ' ',
  showCancelButton,
  onPrimaryActionClick,
  primaryActionButtonText = 'Submit',
  className,
}) => {
  const { shouldRender, shouldAnimate } = useDelayMount(isOpen, 200);

  if (!shouldRender) return null;
  return (
    <Portal>
      <Container
        className={(isOpen && shouldAnimate ? 'open ' : '') + className}
        onClick={onBackdropClick}
      >
        <Modal
          onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();
          }}
        >
          <ModalTitleBar>
            {onClose && <CloseButton onClick={onClose} />}
            <p>{title}</p>
          </ModalTitleBar>
          <form onSubmit={onPrimaryActionClick}>
            <ModalContent>{children}</ModalContent>
            <ModalActionButtons>
              {showCancelButton && (
                <CancelActionButton type='button' onClick={onClose}>
                  Close
                </CancelActionButton>
              )}
              {onPrimaryActionClick && (
                <PrimaryActionButton
                  type='submit'
                  onClick={onPrimaryActionClick}
                >
                  {primaryActionButtonText}
                </PrimaryActionButton>
              )}
            </ModalActionButtons>
          </form>
        </Modal>
      </Container>
    </Portal>
  );
};

export default ModalBase;

const Portal: React.FC = ({ children }: any) => {
  const el = document.getElementById('modal-portal');

  if (!el) return null;
  return createPortal(children, el);
};

import styled from 'styled-components';

export const BlueprintLines = styled.div`
  position: absolute;
  pointer-events: none;
  z-index: -1;

  background: rgba(0, 0, 0, 0.04);
  box-shadow: 0 0 3px rgba(255, 255, 255, 1);
  &.left {
    left: 30px;
    top: 0;
    bottom: 0;
    width: 1px;
    @media (max-width: 570px) {
      left: 15px;
    }
  }
  &.right {
    right: 30px;
    top: 0;
    bottom: 0;
    width: 1px;
    @media (max-width: 570px) {
      right: 15px;
    }
  }
  &.top {
    top: 55px;
    right: 0;
    left: 0;
    height: 1px;
    @media (max-width: 570px) {
      top: 35px;
    }
  }
`;

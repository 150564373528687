import styled from 'styled-components';

export const Card = styled.div`
  background: rgba(255, 255, 255, 0.8);
  backdrop-filter: blur(5px);
  box-shadow: var(--box-shadow-lg);
  padding: var(--indent-spacing);
  width: 100%;
  position: relative;
  z-index: 2;
  margin: 30px 0;
  p {
    line-height: 140%;
  }
  p,
  ul,
  li {
    margin-top: 24px;
    @media (max-width: 400px) {
      font-size: 13px;
    }
  }
  li {
    margin-left: var(--indent-spacing);
  }
`;

export type Review = {
  body: string;
  name: string;
  location: string;
  date: string;
  image: string;
};

export const REVIEWS: Review[] = [
  {
    name: 'Keith H.',
    location: 'TX',
    date: '5/4/2018',
    image:
      'https://s3-media0.fl.yelpcdn.com/photo/R6Plmk9Y_2iFQsGh6HOv4A/120s.jpg',
    body: `We had a few options when searching for an exterminator, but thankfully something told us we should go with Jake at Defense Pest Management. My wife and I are thrilled with the level of service received. Jake was very thorough, explained everything he was doing and let us decide what the best option for our home was.

    We highly recommend going with Defense Pest Management if you have any issues with bugs.`,
  },
  {
    name: 'Tyrone A.',
    location: 'Liberty Hill, TX',
    date: '7/21/2021',
    image:
      'https://s3-media0.fl.yelpcdn.com/photo/HRlowg056APJgwYJqd5QoA/120s.jpg',
    body: `Defense Pest Management has been great. We've had them for going on 2 years and they have shown us nothing but great professionalism. They are timely and responsive to any questions we have.  We had a odd ant issue that come up this year and he was able to show up the next day and fix the issue. I would highly recommend them.`,
  },
  {
    name: 'A D.',
    location: 'Austin, TX',
    date: '8/3/2020',
    image:
      'https://s3-media0.fl.yelpcdn.com/photo/ojkxppzIlaQWQ-aC-xHtvw/120s.jpg',
    body: `Not even a customer, but I called today in a panic after finding out I had driven to the hospital in a car filled top-to-bottom with ants. Jake picked up and, though de-anting cars isn't an offered service, he went out of his way to help me identify the ant species and come up with a three-step plan to get rid of them; bonus points for being cool and professional while I broke down completely in a parking lot about it haha. If I'm ever in a situation where I need pest management for my residence, this place will be first on my list. Thank you!`,
  },
  {
    name: 'Michael B.',
    location: 'Leander, TX',
    date: '7/9/2017',
    image:
      'https://s3-media0.fl.yelpcdn.com/photo/Fo6KsOH-TurRDHxs3ELEAw/120s.jpg',
    body: `DPM is a well-run local business we hired to do a pre-emptive spraying of a house to prevent the creepy-crawlies from getting a six-legged foot in the door. They responded promptly to a request for a quote and scheduled us right away.  Kudos for showing up on time, doing a thorough inspection inside and out, and explaining how their substances are people-and-pet friendly.  

    I did have to ask on four separate occasions if they took credit card before they finally answered me.  (I'll save you the four-question run-around and tell you: YES.)
    
    Will update this rating if anything with compound eyes shows up after the spray but so far it looks good!`,
  },
  {
    name: 'Jossy S.',
    location: 'Pflugerville, TX',
    date: '1/16/2020',
    image:
      'https://s3-media0.fl.yelpcdn.com/photo/JtfY8HtD7LOi1UAtOl6kVA/120s.jpg',
    body: `Jake is very responsive. I highly recommend this buissness. I've been using them for over a year! Great work, great customer service, I am so happy I found them.`,
  },
  {
    name: 'Joe M.',
    location: 'Austin, TX',
    date: '1/17/2020',
    image:
      'https://s3-media0.fl.yelpcdn.com/assets/srv0/yelp_styleguide/514f6997a318/assets/img/default_avatars/user_60_square.png',
    body: `Jake came out today to have a look at possible rodents in the attic. He was on time, honest, and I can tell that he is a hard worker. I would highly recommend Jake and Defense Pest Management to anyone who needs their services!`,
  },
  {
    name: 'Dante B.',
    location: 'Pflugerville, TX',
    date: '6/12/2018',
    image:
      'https://s3-media0.fl.yelpcdn.com/photo/McLl5Xbbaj7wKZ_r7375bw/120s.jpg',
    body: `I had a very positive experience with Jake and Defense Pest Management. Jake arrived at my home exactly at the time he said he would, educated me on the issue I was experiencing, and addressed my problem to 100% satisfaction. Jake and Defense Pest Management is a highly ethical and customer first mentality company. They will be my go to company for life. Very highly recommended.`,
  },
  {
    name: 'Lori D.',
    location: 'Pflugerville, TX',
    date: '6/22/2018',
    image:
      'https://s3-media0.fl.yelpcdn.com/photo/bhYQbW6l-zzhbRcsYIYpUg/120s.jpg',
    body: `These guys are rockstars! We wanted to get a termite inspection for the home we are purchasing. Jake let me know that if I don't need/want the written report, he could swing by and do the inspection for free. For free?!? I  suspiciously asked if this would be a thorough inspection or was this just a way to sell us pest control. He laughed And told me that it is the same inspection, he just won't charge, since he doesn't have to do the paper report and it only takes 20-30 minutes. I nervously booked him. I am delighted to report that they were on time, thorough, knowledgeable and true to their word. We didn't pay a single penny. He worked with a partner, one outside and one in. He picked up on the same leak issue in the master bath shower stall that was barely noticable to the naked eye, but our home inspector had also found. Termites love wet wood, apparently. Luckily, he did not find any. This is a good, honest guy that won't try to rip you off. If we ever nees pest services, we know who we will be calling.`,
  },
  {
    name: 'Tifney C.',
    location: 'Round Rock, TX',
    date: '3/23/2016',
    image:
      'https://s3-media0.fl.yelpcdn.com/photo/0xt4xJwyGPr3_H5UDaBWnQ/120s.jpg',
    body: `I found this company yesterday. I'm new to using pest control services, and they were able to answer all my questions. I was made so comfortable and they scheduled me the next day. When they came out they were very welcoming and thorough. They explained everything to me and did a great job. I will definitely be using this company on a regular basis. I love good, professional customer service.`,
  },
  {
    name: 'David L.',
    location: 'Cedar Park, TX',
    date: '2/12/2016',
    image:
      'https://s3-media0.fl.yelpcdn.com/photo/B4iJTUNgLFC6vnJlpllJEQ/120s.jpg',
    body: `Quick and Awesome service!  Great price! Definitely recommend for all your peat needs.`,
  },
];

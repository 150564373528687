import { REVIEWS, Review } from '../lib/reviews';

import Autoplay from 'embla-carousel-autoplay';
import { Card } from './Card';
import Image from 'next/image';
import ModalBase from './ModalBase';
import styled from 'styled-components';
import { truncateString } from '../lib/truncateString';
import useEmblaCarousel from 'embla-carousel-react';
import { useState } from 'react';

const Container = styled.div`
  position: relative;
  .embla {
    overflow: hidden;
  }
  .embla__container {
    display: flex;
  }
  .embla__slide {
    position: relative;
  }
  .embla__dots {
    display: flex;
    list-style: none;
    justify-content: center;
    padding-top: 10px;
  }
`;

const Slide = styled.div`
  margin: calc(var(--indent-spacing) / 2);
  margin-bottom: var(--indent-spacing);
  min-width: 370px;

  @media (max-width: 580px) {
    min-width: 280px;
  }
`;

const StyledCard = styled(Card)`
  height: 367px;
  @media (max-width: 580px) {
    height: 327px;
  }
  &.in-modal {
    height: auto;
    box-shadow: 0;
    margin: 0;
  }
`;

const ReviewBody = styled.p`
  font-size: 13px;
  color: var(--color-soft-dark);
  line-height: 1.35;
`;

const ReviewHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

const ReviewImage = styled.img`
  border-radius: 50%;
  width: 50px;
  height: 50px;
  margin-right: 12px;
`;

const ReviewHeaderInfo = styled.div``;

const ReviewName = styled.p`
  font-weight: 900;
  font-size: 16px;
  color: var(--color-soft-dark);
`;

const ReviewLocation = styled.p`
  font-weight: 400;
  font-size: 13px;
  color: var(--color-medium);
  opacity: 0.6;
  margin-top: 2px;
`;

const ReviewDate = styled.p`
  font-weight: 400;
  font-size: 13px;
  color: var(--color-medium);
  opacity: 0.6;
  margin-left: 8px;
  margin-top: 0 !important;
`;

const Review5StarsContainer = styled.div`
  margin-top: 18px;
  margin-bottom: 18px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

const ReadMoreButton = styled.button`
  outline: none;
  border-radius: 0;
  border: none;
  text-decoration: none;
  font-weight: 700;
  color: black;
  cursor: pointer;
  padding: 8px 16px;
  margin-left: -16px;
  margin-top: 12px;
  background: transparent;
`;

export const Reviews: React.FC<{}> = () => {
  const [emblaRef, emblaApi] = useEmblaCarousel({ loop: true }, [Autoplay()]);
  const [isReviewModalOpen, setIsReviewModalOpen] = useState<Review>();

  const renderCard = (inModal: boolean, review: Review) => (
    <StyledCard className={inModal ? 'in-modal' : undefined}>
      <ReviewHeader>
        <ReviewImage src={review.image} alt={review.name} />
        <ReviewHeaderInfo>
          <ReviewName>{review.name}</ReviewName>
          <ReviewLocation>{review.location}</ReviewLocation>
        </ReviewHeaderInfo>
      </ReviewHeader>
      <Review5StarsContainer>
        <Image src='/5stars.jpg' alt='5 stars rating' width={113} height={21} />
        <ReviewDate>{review.date}</ReviewDate>
      </Review5StarsContainer>
      <ReviewBody>
        {inModal ? review.body : truncateString(review.body, 200, true)}
      </ReviewBody>
      {!inModal && review.body.length > 200 && (
        <ReadMoreButton
          onClick={inModal ? undefined : () => setIsReviewModalOpen(review)}
        >
          Read More
        </ReadMoreButton>
      )}
    </StyledCard>
  );

  return (
    <Container>
      <div className='embla' ref={emblaRef}>
        <div className='embla__container'>
          {REVIEWS.map((review, i) => {
            return (
              <Slide key={i} className='embla__slide'>
                <ModalBase
                  showCancelButton
                  isOpen={isReviewModalOpen === review}
                  onClose={() => setIsReviewModalOpen(undefined)}
                  onBackdropClick={() => setIsReviewModalOpen(undefined)}
                >
                  {renderCard(true, review)}
                </ModalBase>
                {renderCard(false, review)}
              </Slide>
            );
          })}
        </div>
      </div>
    </Container>
  );
};
